<template>
    <div :class="['d-flex w-100', { 'justify-content-end': noBack }]">
        <st-button
            :callback="goBack"
            variant="light"
            class="mr-auto"
            v-if="!noBack"
        >
            <i class="fas fa-long-arrow-alt-left"></i>{{$t('APPLICATION.FORM.GO_BACK')}}
        </st-button>

        <st-button variant="primary" :callback="previewDocument" v-if="showPreviewBtn">
            <i class="far fa-file"></i>{{$t('APPLICATION.FORM.PREVIEW_DOC')}}
        </st-button>

        <st-button
            variant="light"
            class="ml-4 st-btn-text-primary"
            v-if="showDrafBtn"
            :callback="saveAsDraft"
            :spinner="autoSavePending"
            :disabled="isDraftDisabled || autoSavePending">
            <i class="fas fa-save"></i>{{$t('APPLICATION.FORM.SAVE_AS_DRAFT_BUTTON')}}
        </st-button>

        <st-button variant="primary" :callback="generateDocument" v-if="showGenerateBtn" class="ml-4">
            <i class="far fa-file"></i>{{$t('APPLICATION.FORM.GENERATE_DOCUMENT')}}
        </st-button>
    </div>
</template>

<script>
    import ApplicationDocumentsEditMixin from "@/modules/applications/components/view-application/documents/application-document-edit-mixin";

    export default {
        name: 'ApplicationDocumentsEditToolbar',
        mixins: [ ApplicationDocumentsEditMixin ],
        props: {
            noBack: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            showGenerateBtn() {
                if (!this.generateDocumentActions.length) {
                    return false;
                }

                return !this.isDocumentGenerated 
                    && this.applicationCheckStatus.includes(this.record.internal_status)
                    && this.record.assigned_staff_user_id
                    && this.isCurrentUserAssigned;
            },
            showPreviewBtn() {
                return !this.hidePreviewStatus.includes(this.record.internal_status)
                    && this.isCurrentUserAssigned
                    && !this.isDocumentGenerated;
            },
            showDrafBtn() {
                const applicationReviewAction = this.userActions.filter((el) => el.action_type === this.userActionsTypes.APPLICATION_REVIEW && el.status === this.userActionsStatus.PROCESSING);
                return applicationReviewAction || !this.isDocumentGenerated;
            },
        },
        methods: {
            previewDocument() {
                this.$emit('previewDocument');
            },
            saveAsDraft() {
                this.$emit('saveAsDraft', true);
            },
            generateDocument() {
                this.$emit('generateDocument');
            },
            goBack() {
                this.$router.go(-1);
            },
        },
        
    };
</script>